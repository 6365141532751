<template>
  <div>
    <div class="breadcrumb"> 
      <ul class="breadcrumb__list">
        <li class="breadcrumb__item breadcrumb-item"><a class="breadcrumb__link" href="/" title="Перейти на главную страницу">
            <svg class="icon icon-logo breadcrumb__icon breadcrumb__icon--home">
              <use xlink:href="images/icons/sprite.svg#logo"></use>
            </svg></a></li>
        <li class="breadcrumb__item breadcrumb-item"><span class="breadcrumb__text">{{ $j("help") }}</span></li>
      </ul>
    </div>
    <h1 class="page__title">{{ $j("help") }}</h1>
    <div class="page__content">
      <div class="page__row row">
        <main class="page__col page__main">
          <!-- START block about-->          
            <router-view></router-view>
          <!-- END block about-->
        </main>
        <aside class="page__col page__sidebar page__sidebar--left">
          <div class="sidebar">
            <div class="sidebar__content">
              <div class="sidebar__nav nav nav--sidebar">
                <ul class="nav__list">
                  <li class="nav__item">
                    <router-link class="nav__link help-menu" :class="$route.path==='/help/about'  ? 'nav__link--active' : '' " to="/help/about" @click.native="ActiveClass">{{ $j("about") }}
                      <svg class="icon icon-arrow icon--right nav__link-icon">
                        <use xlink:href="images/icons/sprite.svg#arrow"></use>
                      </svg>
                    </router-link>
                  </li>
                  <li class="nav__item">
                    <router-link class="nav__link help-menu" to="/help/instruction" @click.native="ActiveClass">{{ $j("instruction") }}
                      <svg class="icon icon-arrow icon--right nav__link-icon">
                        <use xlink:href="images/icons/sprite.svg#arrow"></use>
                      </svg>
                    </router-link>
                  </li>
                  <li class="nav__item">
                    <router-link class="nav__link help-menu" to="/help/chavo" @click.native="ActiveClass">{{ $j("chavo") }}
                      <svg class="icon icon-arrow icon--right nav__link-icon">
                        <use xlink:href="images/icons/sprite.svg#arrow"></use>
                      </svg>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    ActiveClass(el) {
      var x = document.getElementsByClassName("help-menu");
      for (var i = 0; i < x.length; i++) {
        x[i].className = ("nav__link help-menu");
      }
      var div = el.currentTarget;
      setTimeout(function(){ 
        div.className = "nav__link help-menu nav__link--active";
       }, 100);
    },
  },
};
</script>
<style scoped>
.about-active {
    background: #4b689c;
    font-weight: 700;
    color: #fff;
}
</style>